export default {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          html:
            '<p class="sv-survey-html">The Nijmegen Questionnaire (NQ) consists of 16 items to be answered on a five-point scale ranging from "never" to "very often". Please rate how often you experience the following items in the following steps:</p><h3 class="sv-survey-ready">Ready to start?</h3>',
          type: 'html',
        },
      ],
    },
    {
      name: 'question1',
      type: 'rating',
      title: 'Chest pain',
      rateValues: [
        { text: 'Never', value: 0 },
        { text: 'Rarely', value: 1 },
        { text: 'Sometimes', value: 2 },
        { text: 'Often', value: 3 },
        { text: 'Very Often', value: 4 },
      ],
    },
    {
      name: 'page3',
      elements: [
        {
          name: 'question2',
          type: 'rating',
          title: 'Feeling tense',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question3',
          type: 'rating',
          title: 'Blurred vision',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page5',
      elements: [
        {
          name: 'question4',
          type: 'rating',
          title: 'Dizzy spells',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page6',
      elements: [
        {
          name: 'question5',
          type: 'rating',
          title: 'Feeling confused',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page7',
      elements: [
        {
          name: 'question6',
          type: 'rating',
          title: 'Faster or deeper breathing',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page8',
      elements: [
        {
          name: 'question7',
          type: 'rating',
          title: 'Short of breath',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page9',
      elements: [
        {
          name: 'question8',
          type: 'rating',
          title: 'Tight feelings in chest',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page10',
      elements: [
        {
          name: 'question9',
          type: 'rating',
          title: 'Bloated feeling in stomach',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page11',
      elements: [
        {
          name: 'question10',
          type: 'rating',
          title: 'Tingling fingers',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page12',
      elements: [
        {
          name: 'question11',
          type: 'rating',
          title: 'Unable to breathe deeply',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page13',
      elements: [
        {
          name: 'question12',
          type: 'rating',
          title: 'Stiff fingers or arms',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page14',
      elements: [
        {
          name: 'question13',
          type: 'rating',
          title: 'Tight feelings round mouth',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page15',
      elements: [
        {
          name: 'question14',
          type: 'rating',
          title: 'Cold hands or feet',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page16',
      elements: [
        {
          name: 'question15',
          type: 'rating',
          title: 'Palpitations',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
    {
      name: 'page17',
      elements: [
        {
          name: 'question16',
          type: 'rating',
          title: 'Feeling of anxiety',
          rateValues: [
            { text: 'Never', value: 0 },
            { text: 'Rarely', value: 1 },
            { text: 'Sometimes', value: 2 },
            { text: 'Often', value: 3 },
            { text: 'Very Often', value: 4 },
          ],
          autoGenerate: false,
        },
      ],
    },
  ],
  title: 'Nijmegen Questionnaire (NQ)',
  showTitle: false,
  completeText: 'Complete',
  pageNextText: 'Skip',
  showProgressBar: 'bottom',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  firstPageIsStarted: true,
  startSurveyText: 'Yes, Continue',
}
