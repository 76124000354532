export default {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          name: 'question1',
          type: 'rating',
          title: 'Feeling nervous, anxious, or on edge',
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question2',
          type: 'rating',
          title: 'Not being able to stop or control worrying',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
          colCount: 4, // This sets the buttons to be inline if space allows
        },
        {
          name: 'question3',
          type: 'rating',
          title: 'Worrying too much about different things',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question4',
          type: 'rating',
          title: 'Trouble relaxing',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question5',
          type: 'rating',
          title: "Being so restless that it's hard to sit still",
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question6',
          type: 'rating',
          title: 'Becoming easily annoyed or irritable',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
        {
          name: 'question7',
          type: 'rating',
          title: 'Feeling afraid, as if something awful might happen',
          hideNumber: true,
          rateValues: [
            { text: 'Not at all', value: 0 },
            { text: 'Several days', value: 1 },
            { text: 'More than half the days', value: 2 },
            { text: 'Nearly every day', value: 3 },
          ],
        },
      ],
      description:
        'Over the last two weeks, how often have you been bothered by the following problems?',
    },
  ],
  title: 'Generalised Anxiety Disorder Assessment (GAD7)',
  showTitle: false,
  completeText: 'Complete',
  pageNextText: 'Skip',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
  showProgressBar: 'belowHeader',
  progressBarType: 'pages',
  progressBarShowPageNumbers: true,
  progressBarShowPageTitles: true,
}
