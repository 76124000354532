export default {
  pages: [
    {
      name: 'page1',

      elements: [
        {
          name: 'Intro',

          type: 'panel',

          elements: [
            {
              html:
                '<p>Most of the motor skills that this questionnaire asks about are things that your child does with his or her hands, or when moving. A child’s coordination may improve each year as they grow and develop. For this reason, it will be easier for you to answer the questions if you think about other children that you know who are the same age as your child. Please compare the degree of coordination your child has with other children of the same age when answering the questions.</p>',

              name: 'question0',

              type: 'html',
            },

            {
              name: 'question0_1',

              type: 'rating',

              title: 'Ready to start?',

              hideNumber: true,

              rateValues: ['Start'],

              titleLocation: 'hidden',
            },
          ],
        },
      ],
    },

    {
      name: 'page2',

      elements: [
        {
          name: 'question1',

          type: 'rating',

          title: 'Your child throws a ball in a controlled and accurate fashion.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question2',

          type: 'rating',

          title:
            'Your child catches a small ball (e.g., tennis ball size) thrown from a distance of 6 to 8 feet (1.8 to 2.4 meters).',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question3',

          type: 'rating',

          title: 'Your child hits an approaching ball or birdie with a bat or racquet accurately',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question4',

          type: 'rating',

          title: 'Your child jumps easily over obstacles found in garden or play environment.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question5',

          type: 'rating',

          title:
            'Your child runs as fast and in a similar way to other children of the same gender and age.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question6',

          type: 'rating',

          title:
            'If your child has a plan to do a motor activity, he/she can organize his/her body to follow the plan and effectively complete the task (e.g., building a cardboard or cushion "fort," moving on playground equipment, building a house or a structure with blocks, or using craft materials).',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question7',

          type: 'rating',

          title:
            'Your child’s printing or writing or drawing in class is fast enough to keep up with the rest of the children in the class.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question8',

          type: 'rating',

          title:
            'Your child’s printing or writing letters, numbers and words is legible, precise and accurate or, if your child is not yet printing, he or she colors and draws in a coordinated way and makes pictures that you can recognize.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question9',

          type: 'rating',

          title:
            'Your child uses appropriate effort or tension when printing or writing or drawing (no excessive pressure or tightness of grasp on the pencil, writing is not too heavy or dark, or too light).',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question10',

          type: 'rating',

          title: 'Your child cuts out pictures and shapes accurately and easily.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question11',

          type: 'rating',

          title:
            'Your child is interested in and likes participating in sports or active games requiring good motor skills.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question12',

          type: 'rating',

          title:
            'Your child learns new motor tasks (e.g., swimming, rollerblading) easily and does not require more practice or time than other children to achieve the same level of skill.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question13',

          type: 'rating',

          title:
            'Your child is quick and competent in tidying up, putting on shoes, tying shoes, dressing, etc.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question14',

          type: 'rating',

          title:
            'Your child would never be described as a “bull in a china shop” (that is, appears so clumsy that he or she might break fragile things in a small room). ',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },

    {
      name: 'page3',

      elements: [
        {
          name: 'question15',

          type: 'rating',

          title:
            'Your child does not fatigue easily or appear to slouch and “fall out” of the chair if required to sit for long periods.',

          rateValues: [
            {
              text: 'Not at all like your child',

              value: '1',
            },

            {
              text: 'A bit like your child',

              value: '2',
            },

            {
              text: 'Moderately like your child',

              value: '3',
            },

            {
              text: 'Quite a bit like your child',

              value: '4',
            },

            {
              text: 'Extremely like your child',

              value: '5',
            },
          ],
        },
      ],
    },
  ],

  title: 'The Developmental Coordination Disorder Questionnaire (DCDQ)',

  showTitle: false,

  completeText: 'Skip',

  logoPosition: 'right',

  pageNextText: 'Skip',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  startSurveyText: 'Yes, Continue',

  firstPageIsStarted: true,

  showCompleteButton: false,
}
