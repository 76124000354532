export default {
  pages: [
    {
      name: 'page',

      elements: [
        {
          name: 'question1',

          type: 'rating',

          title: 'Complain of aches or pains',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question2',

          type: 'rating',

          title: 'Spend more time alone',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question3',

          type: 'rating',

          title: 'Tire easily, little energy',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question4',

          type: 'rating',

          title: 'Fidgety, unable to sit still',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question5',

          type: 'rating',

          title: 'Have trouble with teacher',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question6',

          type: 'rating',

          title: 'Less interested in school',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question7',

          type: 'rating',

          title: 'Act as if driven by motor',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question8',

          type: 'rating',

          title: 'Daydream too much',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question9',

          type: 'rating',

          title: 'Distract easily',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question10',

          type: 'rating',

          title: 'Are afraid of new situations',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question11',

          type: 'rating',

          title: 'Feel sad, unhappy',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question12',

          type: 'rating',

          title: 'Are irritable, angry',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question13',

          type: 'rating',

          title: 'Feel hopeless',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question14',

          type: 'rating',

          title: 'Have trouble concentrating',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question15',

          type: 'rating',

          title: 'Less interested in friends',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question16',

          type: 'rating',

          title: 'Fight with other children',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question17',

          type: 'rating',

          title: 'Absent from school',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question18',

          type: 'rating',

          title: 'School grades dropping',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question19',

          type: 'rating',

          title: 'Down on yourself',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question20',

          type: 'rating',

          title: 'Visits doctor with doctor finding nothing wrong',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question21',

          type: 'rating',

          title: 'Have trouble sleeping',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question22',

          type: 'rating',

          title: 'Worry a lot',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question23',

          type: 'rating',

          title: 'Want to be with parent more than before',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question24',

          type: 'rating',

          title: 'Feel that you are bad',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question25',

          type: 'rating',

          title: 'Take unnecessary risks',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question26',

          type: 'rating',

          title: 'Get hurt frequently',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question27',

          type: 'rating',

          title: 'Seem to be having less fun',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question28',

          type: 'rating',

          title: 'Act younger than children your age',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question29',

          type: 'rating',

          title: 'Do not listen to rules',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question30',

          type: 'rating',

          title: 'Do not show feelings',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question31',

          type: 'rating',

          title: 'Do not understand other people’s feelings',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question32',

          type: 'rating',

          title: 'Tease others',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question33',

          type: 'rating',

          title: 'Blame others for your troubles',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question34',

          type: 'rating',

          title: 'Take things that do not belong to you',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },

        {
          name: 'question35',

          type: 'rating',

          title: 'Refuse to share',

          rateValues: [
            {
              text: 'Never',

              value: 0,
            },

            {
              text: 'Sometimes',

              value: 1,
            },

            {
              text: 'Often',

              value: 2,
            },
          ],
        },
      ],

      description: 'Please mark under the heading that best fits you:',
    },
  ],

  title: 'Pediatric Symptom Checklist - Youth Report (Y-PSC)',

  completeText: 'Skip',

  showTitle: false,

  logoPosition: 'right',

  pageNextText: 'Skip',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  questionsOnPageMode: 'questionPerPage',
}
