export default {
  pages: [
    {
      name: 'Auditory Processing (i)',
      title: 'Auditory Processing (i)',
      elements: [
        {
          name: 'question1',
          type: 'rating',
          title:
            'How often does your child respond negatively to unexpected or loud noises (for example, hide or cringe at noise from ambulance, train, fire or car alarm, fireworks)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question2',
          type: 'rating',
          title:
            'How often does your child become distracted, or have difficulty following verbal instructions when there is a lot of noise around?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question3',
          type: 'rating',
          title: 'How often does your child hold his/her hands over the ears?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question4',
          type: 'rating',
          title:
            'How often does your child appear not to hear what you say (for example, does not seem to pay attention to what you say, appears to ignore you)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question5',
          type: 'rating',
          title:
            'How often does your child have trouble working with background noise (for example, air conditioner, traffic noises, airplanes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question6',
          type: 'rating',
          title:
            'How often does your child not respond when his/her name is called, even though you know the child’s hearing is not a problem?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question7',
          type: 'rating',
          title:
            'How often is your child unusually angry, frightened, or in pain when others cry or scream?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question8',
          type: 'rating',
          title:
            'How often do you have to speak loudly or get very close to your child’s face to get your child’s attention?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question9',
          type: 'rating',
          title:
            'How often does your child seem unaware of continuous noise in the environment (for example, TV, stereo)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question10',
          type: 'rating',
          title:
            'How often does your child seem overly aware, distracted, or disturbed by continuous noise in the environment (for example, TV, stereo)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Auditory Processing (ii)',
      title: 'Auditory Processing (ii)',
      elements: [
        {
          name: 'question11',
          type: 'rating',
          title:
            'How often does your child take a long time to respond when spoken to, even to familiar voices?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question12',
          type: 'rating',
          title:
            'How often does your child startle easily at sound, compared to other children the same age, with loud or high- pitched noises (for example, vacuum, blender, fire alarms)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question13',
          type: 'rating',
          title:
            'How often is your child distracted by sounds not normally noticed by other people (for example, airconditioning fans, trains or planes outside)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question14',
          type: 'rating',
          title:
            'How often does your child respond negatively (i.e. tantrum, become distracted or anxious) when entering places with continuous background noises (for example, grocery stores, schools, shopping malls)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Visual Processing',
      title: 'Visual Processing',
      elements: [
        {
          name: 'question15',
          type: 'rating',
          title:
            'How often is your child bothered by bright lights after your eyes or other children’s eyes have adapted to the same light?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question16',
          type: 'rating',
          title: 'How often does your child cover his/her eyes or squint?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question17',
          type: 'rating',
          title: 'How often does your child seem unable to tolerate bright lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question18',
          type: 'rating',
          title: 'How often does your child seem unable to tolerate flashing lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question19',
          type: 'rating',
          title: 'How often does your child get fussy when exposed to bright lights?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question20',
          type: 'rating',
          title:
            'How often does your child seem sensitive to bright lights (for example, cries or closes eyes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question21',
          type: 'rating',
          title:
            'How often does your child seem sensitive to flashing lights (for example, cries or closes eyes)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question22',
          type: 'rating',
          title: 'How often does your child hesitate to go outside when it’s sunny?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question23',
          type: 'rating',
          title: 'How often does your child seem easily distracted by movement he/she can see?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question24',
          type: 'rating',
          title:
            'How often does your child seem easily distracted by movements of objects (i.e. mechanical toys or cars)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Tactile Processing (Touch) (i)',
      title: 'Tactile Processing (Touch) (i)',
      elements: [
        {
          name: 'question25',
          type: 'rating',
          title: 'How often does your child seem distressed by tooth-brushing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question26',
          type: 'rating',
          title: 'How often does your child seem distressed by face-washing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question27',
          type: 'rating',
          title: 'How often does your child seem distressed by fingernail-cutting?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question28',
          type: 'rating',
          title: 'How often does your child seem distressed by hair-brushing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question29',
          type: 'rating',
          title:
            'How often does your child insist that labels or tags be removed from most clothing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question30',
          type: 'rating',
          title:
            'How often does your child refuse to wear certain fabrics or cry or fuss in response to wearing certain fabrics?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question31',
          type: 'rating',
          title:
            'How often does your child complain that certain garments are too tight or scratchy?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question32',
          type: 'rating',
          title: 'How often does your child prefer to not wear clothing?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question33',
          type: 'rating',
          title: 'How often does your child resist hugging?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question34',
          type: 'rating',
          title: 'How often does your child react negatively or aggressively to hand-holding?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Tactile Processing (Touch) (ii)',
      title: 'Tactile Processing (Touch) (ii)',
      elements: [
        {
          name: 'question35',
          type: 'rating',
          title: 'How often does your child react emotionally or aggressively to being touched?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question36',
          type: 'rating',
          title:
            'How often does your child react emotionally or aggressively when touching very cold objects with his/her hands?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question37',
          type: 'rating',
          title:
            'How often does your child react emotionally or aggressively when very cold objects touch his/her face?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Ingestion and Digestion (i)',
      title: 'Ingestion and Digestion (i)',
      elements: [
        {
          name: 'question38',
          type: 'rating',
          title: 'How often does your child avoid certain tastes?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question39',
          type: 'rating',
          title: 'How often does your child resist certain textures of food?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question40',
          type: 'rating',
          title: 'How often does your child avoid certain food smells?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question41',
          type: 'rating',
          title: 'How often does your child resist certain temperatures of food?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question42',
          type: 'rating',
          title: 'How often does your child gag?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question43',
          type: 'rating',
          title: 'How often does your child vomit?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question44',
          type: 'rating',
          title: 'How often does your child have acid reflux?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question45',
          type: 'rating',
          title: 'How often does your child have excessive intestinal gas?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question46',
          type: 'rating',
          title: 'How often does your child become constipated?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question47',
          type: 'rating',
          title: 'How often does your child experience stomach or intestinal cramping?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
    {
      name: 'Ingestion and Digestion (ii)',
      title: 'Ingestion and Digestion (ii)',
      elements: [
        {
          name: 'question48',
          type: 'rating',
          title: 'How often does your child seem to have difficulty swallowing solid foods?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question49',
          type: 'rating',
          title:
            'How often does your child suck on objects other than food (for example, pacifier, own tongue, thumb)?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
        {
          name: 'question50',
          type: 'rating',
          title:
            'How often does your child eat (or want to eat) significantly less than you think is appropriate for his/her size or age?',
          rateValues: [
            { text: 'Almost Always', value: 4 },
            { text: 'Frequently/ Often', value: 3 },
            { text: 'Sometimes/ Occasionally', value: 2 },
            { text: 'Almost Never ', value: 1 },
            { text: 'Not Sure/ Not Applicable', value: 0 },
          ],
        },
      ],
    },
  ],
  title: 'BBC Questionnaire (Child - Caregiver)',
  showTitle: false,
  completeText: 'Skip',
  pageNextText: 'Skip',
  showProgressBar: 'bottom',
  goNextPageAutomatic: true,
  questionsOnPageMode: 'questionPerPage',
}
