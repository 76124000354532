/**
 * This is a page for updating existing assessment
 */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Container, Typography, Grid, GlobalStyles } from '@mui/material'
import Alert from '@mui/material/Alert'
import { get } from 'lodash'
import { useMutation } from 'utils/apollo'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

import Header from './components/Header'
import { useSnackbar } from 'notistack'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ACTIONS } from './utils/constants'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'utils/moment'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'
import globalStyles from 'styles/GlobalStyles'

import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import gad7 from './utils/gad7'
import bpqSf from './utils/bpq-sf'
import dcdq from './utils/dcdq'
import pcl5Week from './utils/pcl5-week'
import npss from './utils/npss'
import psc from './utils/psc'
import bpqAns from './utils/bpq-ans'
import nq from './utils/nq'
import phq from './utils/phq'
import ais from './utils/ais'
import ace from './utils/ace'
import bbcss13 from './utils/bbcss13'
import bbcss12 from './utils/bbcss12'
import { parseMetadata } from './utils/parse-metadata'
import { DefaultLightPanelless } from 'survey-core/themes'

const HeaderTitle = ({ pageTitle, name }) => (
  <span>
    {pageTitle} | <span className="text-link font-semibold">{name}</span>
  </span>
)
export default function UpdateAssessment() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // react component states and ref
  const componentRef = useRef(null)
  const [showPrintObjects, setShowPrintObjects] = useState(false)

  const { userId: _userId, sessionId: _sessionId } = useParams()
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const sessionId = parseInt(_sessionId)
  const userId = parseInt(_userId)

  const {
    SENT_TO_CLIENT,
    IN_PROGRESS,
    COMPLETED,
    isSent,
    isInProgress,
    isCompleted,
  } = ASSESSMENT_STATUS

  // search for assessment data
  const {
    setHideHeader,
    setTitle,
    refetch,
    setLoadingStateWithTimer,
    onLoadKPI,
    loadAssessmentsForUpsert,
  } = useOutletContext()

  const { selectedUser } = useGetUserWithoutRefresh(userId)

  const users = useSelector((state) => get(state, 'assessments.userData', []))
  const { fullName, email } = users.find(({ id }) => parseInt(userId) === id) ?? {}
  const name = email ? `${fullName} (${email})` : fullName

  const [updateSession] = useMutation(UPDATE_SESSION, {
    onCompleted: () => {
      onLoadKPI()
    },
  })
  const [assessmentData, setAssessmentData] = useState({})

  // we need to watch this state and rerender..
  const { sessionData = {}, title, isIntakeForm, updatedAt, metadata } = assessmentData
  console.log({ sessionData })

  useOnValueChange(JSON.stringify({ userId, sessionId, productEvents, sessionData }), async () => {
    setHideHeader(true)
    if (userId && sessionId) {
      const response = await loadAssessmentsForUpsert({
        userIds: [parseInt(userId)],
        sessionId,
      })
      setAssessmentData({
        ...response,
        isInProgressState: isInProgress(response.sessionData.status),
        isCompletedState: isCompleted(response.sessionData.status),
      })

      if (isInProgress(response.sessionData.status)) {
        setTitle(<HeaderTitle pageTitle={'Continue Assessment'} name={name} />)
      } else {
        setTitle(<HeaderTitle pageTitle={'View Assessment'} name={name} />)
      }
    }
  })

  // new assessment Data retreives data on load

  // if a user clicks back, we never return with a filter
  const onClick = (statusPassIn) => async (event, options = {}) => {
    // if you've sent to client, status will be locked as sent to client until client completes assessments
    const status =
      isSent(sessionData.status) && isInProgress(statusPassIn) ? SENT_TO_CLIENT : statusPassIn
    await setLoadingStateWithTimer(true)
    const score =
      status === 'Completed' ? getAssessmentScore({ ...survey.data }, metadata) : undefined
    const data = { answers: { ...survey.data, score }, status, currentPageNo: survey.currentPageNo }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await refetch()
      if (options.fromBack) {
        navigate(`/assessments`, { state: { hideGettingStarted: true } })
      } else {
        navigate(`/assessments/${userId}`, { state: { hideGettingStarted: true } })
      }
      enqueueSnackbar(ACTIONS[status].message, {
        variant: ACTIONS[status].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoadingStateWithTimer(false)
    }
  }

  // is first page does not work here because surveyJS thinks we're on first page on load
  const mp = {
    'Generalised Anxiety Disorder (GAD-7)': gad7,
    'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)': bpqAns,
    'Body Perception Questionnaire Short Form (BPQ-SF)': bpqSf,
    'The Developmental Coordination Disorder Questionnaire (DCDQ)': dcdq,
    'Neuroception of Psychological Safety Scale (NPSS)': npss,
    'PTSD Checklist for DSM-5 (PCL-5) - Week': pcl5Week,
    'Pediatric Symptom Checklist (PSC)': psc,
    'Nijmegen Questionnaire (NQ)': nq,
    'Patient Health Questionnaire (PHQ-9)': phq,
    'Athens Insomnia Scale (AIS)': ais,
    'Adverse Childhood Experience for Adults (ACE)': ace,
    'Brain Body Center Sensory Scales (BBCSS) - Full': bbcss13,
    'Brain Body Center Sensory Scales (BBCSS) - Full (Child)': bbcss12,
  }
  const { assessmentName } = parseMetadata(metadata)
  const survey = new Model(get(mp, assessmentName, gad7))
  survey.applyTheme(DefaultLightPanelless)

  useEffect(() => {
    setLoadingStateWithTimer(!survey)
  }, [survey])

  // const model = new Survey.Model(survey)
  const lastVisitedPage = sessionData?.currentPageNumber || sessionData?.currentPageNo

  const autoSave = async (event) => {
    try {
      const surveyData = get(event, 'valuesHash', {})
      const score = getAssessmentScore({ ...surveyData }, metadata)
      const data = {
        answers: { ...surveyData, score },
        status: IN_PROGRESS,
        currentPageNo: survey.currentPageNo,
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: sessionId,
        metadata: { userAgent: navigator.userAgent },
      }
      // do not auto update sent to client or completed
      if (isInProgress(sessionData.status)) {
        await dispatch(autoSaveAssessment({ session }))
      }
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
      })
    }
  }

  const handleOnBeforeGetContent = () => {
    setShowPrintObjects(true)
  }

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
    // eslint-disable-next-line
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onBeforeGetContent: handleOnBeforeGetContent,
  })

  const setStateThenPrint = async () => {
    await setShowPrintObjects(true)
    await handlePrint()
    await setShowPrintObjects(false)
  }

  return (
    <Container className="py-5">
      {isSent(sessionData.status) && (
        <Alert severity="info">
          Read only - this assessment has been sent to your client and can no longer be edited by
          you
        </Alert>
      )}
      <Header
        onSend={onClick(SENT_TO_CLIENT)}
        onSave={onClick(IN_PROGRESS)}
        title={title}
        data={sessionData}
        handlePrint={setStateThenPrint}
        isIntakeForm={isIntakeForm}
      />

      {!!survey && (
        <div ref={componentRef} className={showPrintObjects ? 'pt-3 px-8' : ''}>
          {showPrintObjects && (
            <>
              {isIntakeForm && <GlobalStyles styles={globalStyles} />}
              <Grid container alignItems="stretch" justifyContent="center" spacing={3}>
                <Grid item>
                  <img
                    src="/images/unyte-logo.png"
                    alt="Unyte"
                    style={{ width: '50px', marginTop: '25px' }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h5">{title}</Typography>
                  <Grid item>
                    <Typography variant="body1">{`${selectedUser.fullName} (${selectedUser.email})`}</Typography>
                    <Typography variant="body1">
                      {updatedAt
                        ? moment
                            .withoutTimezone(updatedAt)
                            .utcOffset(0)
                            .format('MM/DD/YYYY')
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Survey
            model={survey}
            mode={!isInProgress(sessionData.status) ? 'display' : 'default'}
            data={sessionData.answers}
            currentPageNo={lastVisitedPage}
            onComplete={onClick(COMPLETED)}
            widthMode="responsive"
            showProgressBar="off"
            showNavigationButtons="bottom"
            goNextPageAutomatic={true}
            onCurrentPageChanged={autoSave}
            questionsOnPageMode={
              isInProgress(sessionData.status) ? 'questionPerPage' : 'singlePage'
            }
          />
        </div>
      )}
      <div style={{ height: '100px' }} />
    </Container>
  )
}
