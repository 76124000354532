export default {
  pages: [
    {
      name: 'page1',

      description:
        'Please rate how well the following statements describe your feelings over the past week.',

      elements: [
        {
          name: 'question1',

          type: 'rating',

          title: 'I felt valued',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question2',

          type: 'rating',

          title: 'I felt comfortable expressing myself',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question3',

          type: 'rating',

          title: 'I felt accepted by others',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question4',

          type: 'rating',

          title: 'I felt understood',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question5',

          type: 'rating',

          title: 'I felt like others got me',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question6',

          type: 'rating',

          title: 'I felt respected',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question7',

          type: 'rating',

          title: 'There was someone who made me feel safe',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question8',

          type: 'rating',

          title: 'There was someone that I could trust',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question9',

          type: 'rating',

          title: 'I felt comforted by others',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question10',

          type: 'rating',

          title: 'I felt heard by others',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question11',

          type: 'rating',

          title: 'I felt like people would try their best to help me',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question12',

          type: 'rating',

          title: 'I felt cared for',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question13',

          type: 'rating',

          title: 'I felt wanted',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question14',

          type: 'rating',

          title: 'I didn’t feel judged by others',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question15',

          type: 'rating',

          title: 'I felt able to empathise with other people',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question16',

          type: 'rating',

          title: 'I felt able to comfort another person if needed',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question17',

          type: 'rating',

          title: 'I felt compassion for others',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question18',

          type: 'rating',

          title: 'I wanted to help others relax',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question19',

          type: 'rating',

          title: 'I felt like I could comfort a loved one',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question20',

          type: 'rating',

          title: 'I felt so connected to others I wanted to help them',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question21',

          type: 'rating',

          title: 'I felt caring',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question22',

          type: 'rating',

          title: 'My heart rate felt steady',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question23',

          type: 'rating',

          title: 'Breathing felt effortless',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question24',

          type: 'rating',

          title: 'My voice felt normal',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question25',

          type: 'rating',

          title: 'My body felt relaxed',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question26',

          type: 'rating',

          title: 'My stomach felt settled',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question27',

          type: 'rating',

          title: 'My breathing was steady',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question28',

          type: 'rating',

          title: 'I felt able to stay still',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },

        {
          name: 'question29',

          type: 'rating',

          title: 'My face felt relaxed',

          rateValues: [
            {
              text: 'Strongly Disagree',

              value: '1',
            },

            {
              text: 'Disagree',

              value: '2',
            },

            {
              text: 'Neither Agree or Disagree',

              value: '3',
            },

            {
              text: 'Agree',

              value: '4',
            },

            {
              text: 'Strongly Agree',

              value: '5',
            },
          ],
        },
      ],
    },
  ],

  title: 'Neuroception of Psychological Safety Scale (NPSS)',

  showTitle: false,

  completeText: 'Complete',

  logoPosition: 'right',

  pageNextText: 'Next',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  questionsOnPageMode: 'questionPerPage',
}
