import React, { useState } from 'react'
import { Box, Divider, Grid, Stack, Typography } from 'components'
import { UsersSearch } from '../components/Search'
import { Chip, Tooltip } from '@mui/material'
import { Done } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import { get } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { GET_ACTIVE_CLIENTS_BY_IDS } from './constants/constants'

export default ({ queryVars, setQueryVars }) => {
  const { showNewSubscriptionPlan, showNewClientsPage } = useSelector((state) => state.ff)
  const { setFilterState, chipState, setChipState, debouncedSearch } = useOutletContext()

  // show ssp or rrp or focus?
  const {
    selectedUser: {
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
    },
  } = useGetUserWithoutRefresh()
  const { hasSspProducts, hasRrpProducts, hasFocusProducts } = useSelector((state) =>
    get(state, 'ff', {})
  )

  // fetch active clients if we're still on old plan
  const [activeIdState, setActiveIds] = useState({ activeIds: [], loaded: false })
  const [loadActiveClientsId] = useLazyQuery(GET_ACTIVE_CLIENTS_BY_IDS, {
    onCompleted: (data) => {
      const activeIds = get(data, 'getActiveClientsByUserIds', [])
        .filter(({ isActive }) => isActive)
        .map(({ id }) => id)
      setActiveIds({ activeIds, loaded: true })
    },
  })

  // this is for onLoad
  useOnValueChange(JSON.stringify({ showNewSubscriptionPlan }), () => {
    if (!showNewSubscriptionPlan && !activeIdState.activeIds.length && !activeIdState.loaded) {
      loadActiveClientsId()
    }
  })

  const showSSPFilterChip = hasCompletedSspCertification && hasSspProducts
  const showRRPFilterChip = hasCompletedRrpCertification && hasRrpProducts
  const showFocusFilterChip = hasCompletedFocusCertification && hasFocusProducts
  const inviteMode = showNewClientsPage ? [true, false] : [false]
  const filterChips = [
    {
      id: 'activeClient',
      label: () => 'Active Client',
      queryUp: { ids: activeIdState.activeIds },
      queryDown: { ids: undefined },
      isEnabled: ({ showNewSubscriptionPlan }) => !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Active Clients',
    },
    {
      id: 'archived',
      label: () => 'Archived',
      queryUp: { isArchived: true },
      queryDown: { isArchived: false },
      isEnabled: () => true,
      tooltipMessage: () => 'Show Archived Clients',
    },
    {
      id: 'suspended',
      label: () => 'Suspended',
      queryUp: { isSuspended: true },
      queryDown: { isSuspended: undefined },
      isEnabled: () => true,
      tooltipMessage: () => 'Show Suspended Clients',
    },
    {
      id: 'ssp',
      label: () => 'SSP Enabled',
      queryUp: { categories: ['ssp'] },
      querydown: { categories: undefined },
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients with SSP Enabled',
    },
    {
      id: 'rrp',
      label: () => 'RRP Enabled',
      queryUp: { categories: ['rrp'] },
      querydown: { categories: undefined },
      isEnabled: ({ showRRPFilterChip, showNewSubscriptionPlan }) =>
        showRRPFilterChip && !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients with RRP Enabled',
    },
    {
      id: 'focus',
      label: () => 'ILS Enabled',
      queryUp: { categories: ['focus'] },
      querydown: { categories: undefined },
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && !showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients with ILS Enabled',
    },
    {
      id: 'sspUse',
      label: () => 'Using SSP',
      queryUp: { sspSlotStatuses: ['used'] },
      queryDown: { sspSlotStatuses: undefined },
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients using SSP',
    },
    {
      id: 'rrpUse',
      label: () => 'Using RRP',
      queryUp: { rrpSlotStatuses: ['used'] },
      queryDown: { rrpSlotStatuses: undefined },
      isEnabled: ({ showRRPFilterChip, showNewSubscriptionPlan }) =>
        showRRPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients using RRP',
    },
    {
      id: 'focusUse',
      label: () => 'Using ILS',
      queryUp: { focusSlotStatuses: ['used'] },
      queryDown: { focusSlotStatuses: undefined },
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'Show Clients using ILS',
    },
    {
      id: 'sspAssigned',
      label: () => 'SSP Not Started',
      queryUp: { sspSlotStatuses: ['assigned'] },
      queryDown: { sspSlotStatuses: undefined },
      isEnabled: ({ showSSPFilterChip, showNewSubscriptionPlan }) =>
        showSSPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'SSP Assigned but Not Started',
    },
    {
      id: 'rrpAssigned',
      label: () => 'RRP Not Started',
      queryUp: { rrpSlotStatuses: ['assigned'] },
      queryDown: { rrpSlotStatuses: undefined },
      isEnabled: ({ showRRPFilterChip, showNewSubscriptionPlan }) =>
        showRRPFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'RRP Assigned but Not Started',
    },
    {
      id: 'focusAssigned',
      label: () => 'ILS Not Started',
      queryUp: { focusSlotStatuses: ['assigned'] },
      queryDown: { focusSlotStatuses: undefined },
      isEnabled: ({ showFocusFilterChip, showNewSubscriptionPlan }) =>
        showFocusFilterChip && showNewSubscriptionPlan,
      tooltipMessage: () => 'ILS Assigned but Not Started',
    },
    {
      id: 'remote',
      label: () => 'Remote Access Enabled',
      queryUp: { isRemoteUser: true, isInvitationMode: [false] },
      queryDown: { isRemoteUser: undefined, isInvitationMode: inviteMode },
      isEnabled: () => true,
      tooltipMessage: () => 'Show Remote Clients',
    },
    {
      id: 'inPerson',
      label: () => 'In-Person Only',
      queryUp: { isRemoteUser: false, isInvitationMode: [false] },
      queryDown: { isRemoteUser: undefined, isInvitationMode: inviteMode },
      isEnabled: () => true,
      tooltipMessage: () => 'Show In-Person only Clients',
    },
    {
      id: 'invitationMode',
      label: () => 'Invitation Only',
      queryUp: { isInvitationMode: [true] },
      queryDown: { isInvitationMode: [true, false] },
      isEnabled: ({ showNewClientsPage }) => showNewClientsPage,
      tooltipMessage: () => 'Show Only the Unaccepted Invitations to Clients',
    },
  ].filter(({ isEnabled }) =>
    isEnabled({
      showSSPFilterChip,
      showRRPFilterChip,
      showFocusFilterChip,
      showNewSubscriptionPlan,
      showNewClientsPage,
    })
  )

  const handleClick = ({ id, queryUp, queryDown }) => () => {
    setChipState((prevState) => {
      if (!prevState[id]) {
        setFilterState((prevState) => ({
          ...prevState,
          ...queryUp,
        }))
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          ...queryDown,
        }))
      }
      return {
        ...prevState,
        [id]: !prevState[id],
      }
    })
  }

  const isChipDisabled = (chipId) => {
    // since filters are AND condition, disable any combinations that are invalid
    return (
      (chipId === 'remote' && (chipState['inPerson'] || chipState['invitationMode'])) ||
      (chipId === 'inPerson' && (chipState['remote'] || chipState['invitationMode'])) ||
      (chipId === 'invitationMode' && (chipState['inPerson'] || chipState['remote'])) ||
      (chipId === 'sspAssigned' && chipState['sspUse']) ||
      (chipId === 'sspUse' && chipState['sspAssigned']) ||
      (chipId === 'rrpAssigned' && chipState['rrpUse']) ||
      (chipId === 'rrpUse' && chipState['rrpAssigned']) ||
      (chipId === 'focusAssigned' && chipState['focusUse']) ||
      (chipId === 'focusUse' && chipState['focusAssigned'])
    )
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={5} md={5} lg={5}>
        <UsersSearch
          onSearchChange={(val) => {
            debouncedSearch(val)
          }}
          hideSwitch
          width="90%"
        />
      </Grid>
      <Grid container item xs={12} sm={7} md={7} lg={7} justifyContent="end">
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          p={1}
          alignItems="center"
        >
          <Tooltip
            title="Click one or more filters to only view Clients with specified attributes"
            placement="top"
          >
            <Typography variant="h6" className="mr-3">
              Filters
            </Typography>
          </Tooltip>
          <Box className="ml-3">
            {filterChips.map((chip, index) => {
              const isChecked = chipState[chip.id]
              const chipColor = isChecked ? '#EEF7F3' : 'white'
              return (
                <Tooltip
                  title={chip.tooltipMessage({
                    showNewSubscriptionPlan,
                  })}
                  placement="top"
                  key={`${chip.id}${index}`}
                >
                  <Chip
                    data-tag={chip.id}
                    avatar={isChecked ? <Done /> : null}
                    label={chip.label({ showNewSubscriptionPlan })}
                    onClick={handleClick({ ...chip })}
                    sx={{ backgroundColor: chipColor, m: 0.5 }}
                    variant="outlined"
                    disabled={isChipDisabled(chip.id)}
                  />
                </Tooltip>
              )
            })}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}
