export default {
  pages: [
    {
      name: 'page1',

      title: 'Autonomic Nervous System Reactivity',

      elements: [
        {
          html:
            '<h1 class="sv-survey-header">Autonomic Nervous System Reactivity</h1>\n<p class="sv-survey-html">The autonomic nervous system is the part of your nervous system that controls your cardiovascular, respiratory, digestive, and temperature regulation systems. It is also involved in the experience and expression of emotions. The autonomic nervous system functions differently among people. This scale has been developed to measure how your autonomic nervous system reacts.</p>\n\n<h3 class="sv-survey-ready">Ready to start?</h2>',

          name: 'Description - ANS Reactivity',

          type: 'html',
        },
      ],
    },

    {
      name: 'page2',
      title: 'Autonomic Nervous System Reactivity',
      elements: [
        {
          name: 'question27',
          type: 'rating',
          title: 'I have difficulty coordinating breathing and eating.',
          hideNumber: false,
          rateValues: [
            {
              text: 'Never',
              value: 1,
            },

            {
              text: 'Occasionally',
              value: 2,
            },
            {
              text: 'Sometimes',
              value: 3,
            },
            {
              text: 'Usually',
              value: 4,
            },
            {
              text: 'Always',
              value: 5,
            },
          ],
        },

        {
          name: 'question28',

          type: 'rating',

          title: 'When I am eating, I have difficulty talking.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question29',

          type: 'rating',

          title: 'My heart often beats irregularly.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question30',

          type: 'rating',

          title: 'When I eat, food feels dry and sticks to my mouth and throat.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question31',

          type: 'rating',

          title: 'I feel shortness of breath.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question32',

          type: 'rating',

          title: 'I have difficulty coordinating breathing with talking.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question33',

          type: 'rating',

          title:
            'When I eat, I have difficulty coordinating swallowing, chewing, and/or sucking with breathing.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question34',

          type: 'rating',

          title: 'I have a persistent cough that interferes with my talking and eating.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question35',

          type: 'rating',

          title: 'I gag from the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question36',

          type: 'rating',

          title: 'I have chest pains.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question37',

          type: 'rating',

          title: 'I gag when I eat.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question38',

          type: 'rating',

          title: 'When I talk, I often feel I should cough or swallow the saliva in my mouth.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question39',

          type: 'rating',

          title: 'When I breathe, I feel like I cannot get enough oxygen.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question40',

          type: 'rating',

          title: 'I have difficulty controlling my eyes.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question41',

          type: 'rating',

          title: 'I feel like vomiting',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question42',

          type: 'rating',

          title: "I have 'sour' stomach.",

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question43',

          type: 'rating',

          title: 'I am constipated',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question44',

          type: 'rating',

          title: 'I have indigestion.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question45',

          type: 'rating',

          title: 'After eating I have digestive problems.',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },

        {
          name: 'question46',

          type: 'rating',

          title: 'I have diarrhea',

          hideNumber: false,

          rateValues: [
            {
              text: 'Never',

              value: 1,
            },

            {
              text: 'Occasionally',

              value: 2,
            },

            {
              text: 'Sometimes',

              value: 3,
            },

            {
              text: 'Usually',

              value: 4,
            },

            {
              text: 'Always',

              value: 5,
            },
          ],
        },
      ],

      description: 'Please rate yourself on the statement:',
    },
  ],

  title: 'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)',

  showTitle: false,

  completeText: 'Complete',

  pageNextText: 'Skip',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  questionsOnPageMode: 'questionPerPage',

  firstPageIsStarted: true,

  startSurveyText: 'Yes, Continue',
}
