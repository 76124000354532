import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useLazyQuery, useMutation } from 'utils/apollo'
import { useDispatch, useSelector } from 'react-redux'
import MainLayout from 'components/containers/main/Main'
import AssessmentsTitle from './components/AssessmentsTitle'
import { NEW_UPDATE_VIDEO } from './utils/constants'
import {
  resetFilter,
  setAssessmentName,
  setAssessmentSessions,
  setFilterDates,
  setUser,
  setUserData,
} from 'store/modules/assessments'
import NewUpdates from '../../components/NewUpdates'
import { get } from 'lodash'
import { Outlet, useLocation, useNavigate } from 'react-router'
import useGetAssessments from './utils/useGetAssessments'
import LoadingPage from 'components/LoadingPage'
import { useGetProductsAssessments } from 'utils/hooks/useGetProducts'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { getComparator, stableSort } from 'components/table/enhanced-table-toolbar'
import { format } from 'date-fns'
import { getAssessmentScore } from './utils/get-assessment-score'
import {
  GET_ASSESSMENTS_NEW,
  GET_ASSESSMENT_BY_STATUS,
  GET_ASSESSMENT_FOR_UPSERT,
  GET_USERS_FOR_ASSESSMENTS,
  UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS,
} from './constants/gql'
import { parseMetadata } from './utils/parse-metadata'

export default function AssessmentsLayout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isSelectPage = pathname === '/assessments/select'

  // redux
  const authUserId = useSelector((state) => get(state, 'auth.user.id', ''))
  const { hasSspProducts, hasFocusProducts, hasRrpProducts, showNewClientsPage } = useSelector(
    (state) => state.ff
  )

  // useStates
  const [hideHeader, setHideHeader] = useState(false)
  const [hideFilter, setHideFilter] = useState(false)
  const [hideTabs, setHideTabs] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('loading...')
  const [title, setTitle] = useState(<AssessmentsTitle />)
  const [assessments, setAssessments] = useState([])
  const [totalAssessmentsCount, setTotalAssessmentsCount] = useState(0)
  const [order, setOrder] = useState('asc') // TABLE HEADERS
  const [orderBy, setOrderBy] = useState('client') // TABLE HEADERS
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  // we have to get assessment product for demographic info
  useGetProductsAssessments()

  const { refetch: getUsers } = useQuery(GET_USERS_FOR_ASSESSMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        anyRoles: 'client',
        includeNoSlotClients: true,
        isInvitationMode: showNewClientsPage ? [true, false] : [false],
      },
    },
    onCompleted: (data) => {
      dispatch(setUserData(get(data, 'getUsers', [])))
    },
  })

  // get product events
  useGetAssessments({})

  // if user has no subscriptions, then they are not able to access assessments page
  useEffect(() => {
    if (
      hasSspProducts !== undefined &&
      hasFocusProducts !== undefined &&
      hasRrpProducts !== undefined
    ) {
      if (!hasSspProducts && !hasFocusProducts && !hasRrpProducts) {
        navigate('/')
      }
    }
  }, [hasSspProducts, hasFocusProducts])

  const setLoadingStateWithTimer = (state, message = 'loading...') => {
    setLoading(state)
    setLoadingMessage(message)
    // default set loading to false after 10 seconds
    setTimeout(() => {
      setLoading(false)
      setLoadingMessage('loading...')
    }, 2000)
  }

  // using -1 to remember initial state, to fix race condition error
  const defaultKPIs = {
    totalCount: -1,
    completedCount: -1,
    inProgressByClientCount: -1,
    inProgressByProviderCount: -1,
    sentCount: -1,
    inProgressCount: 0,
  }
  const [kpi, setKpi] = useState(defaultKPIs)
  const [loadingState, setLoadingState] = useState('NONE')

  // Load counts
  const [loadCompletedCount, { loading: loadingCompletedCount }] = useLazyQuery(
    GET_ASSESSMENT_BY_STATUS,
    {
      variables: {
        filter: {
          status: 'Completed',
        },
      },
      // we need no cache or we won't get sessions count
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const completedCount = get(data, 'getSessionsCount', 0)
        setKpi({ ...kpi, completedCount })
      },
    }
  )

  const [loadSentCount, { loading: loadingSentCount }] = useLazyQuery(GET_ASSESSMENT_BY_STATUS, {
    variables: {
      filter: {
        status: 'Sent to Client',
      },
    },
    // we need no cache or we won't get sessions count
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const sentCount = get(data, 'getSessionsCount', 0)
      setKpi({ ...kpi, sentCount })
    },
  })

  const [loadInProgressByClientCount, { loading: loadingInProgressByClientCount }] = useLazyQuery(
    GET_ASSESSMENT_BY_STATUS,
    {
      variables: {
        filter: {
          status: ['In Progress by Client'],
        },
      },
      // we need no cache or we won't get sessions count
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const inProgressByClientCount = get(data, 'getSessionsCount', 0)
        setKpi({
          ...kpi,
          inProgressByClientCount,
        })
      },
    }
  )

  const [
    loadInProgressByProviderCount,
    { loading: loadingInProgressByProviderCount },
  ] = useLazyQuery(GET_ASSESSMENT_BY_STATUS, {
    variables: {
      filter: {
        status: ['In Progress'],
      },
    },
    // we need no cache or we won't get sessions count
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const inProgressByProviderCount = get(data, 'getSessionsCount', 0)
      setKpi({
        ...kpi,
        inProgressByProviderCount,
      })
    },
  })

  const [loadTotalCount, { loading: loadingTotalCount }] = useLazyQuery(GET_ASSESSMENT_BY_STATUS, {
    variables: {
      filter: {
        types: 'answers',
      },
    },
    // we need no cache or we won't get sessions count
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const totalCount = get(data, 'getSessionsCount', 0)
      setKpi({ ...kpi, totalCount })
    },
  })

  const loadingKPI = {
    totalCount: loadingTotalCount,
    completedCount: loadingCompletedCount,
    inProgressCount: loadingInProgressByProviderCount || loadingInProgressByClientCount,
    sentCount: loadingSentCount,
  }

  // on load - load filter status stuff
  const filterUserId = useSelector((state) => get(state, 'assessments.filter.userId', undefined))
  const filterStatus = useSelector((state) => get(state, 'assessments.filter.status', undefined))
  const filterProductEventId = useSelector((state) =>
    get(state, 'assessments.filter.productEventId', undefined)
  )
  const filterCreatedDate = useSelector((state) =>
    get(state, 'assessments.filter.createdAt', undefined)
  )
  const filterUpdatedDate = useSelector((state) =>
    get(state, 'assessments.filter.updatedAt', undefined)
  )

  // `onLoadKPI` only queries assessment
  const onLoadKPI = async (filter) => {
    const kpiVariables = (status) => ({
      variables: {
        filter: {
          ...filter,
          status,
          types: 'answers',
        },
      },
    })

    try {
      await loadInProgressByProviderCount(kpiVariables('In Progress'))
      await loadInProgressByClientCount(kpiVariables('In Progress by Client'))
      await loadSentCount(kpiVariables('Sent to Client'))
      await loadTotalCount(kpiVariables())
      await loadCompletedCount(kpiVariables('Completed'))
    } catch (error) {
      console.error('error loading KPIs', error)
    } finally {
      setLoadingState('COMPLETE')
    }
  }

  // we don't need to refresh filters when we're not in select page
  useOnValueChange(
    JSON.stringify({
      filterUserId,
      filterProductEventId,
      filterCreatedDate,
      filterUpdatedDate,
      isSelectPage,
    }),
    async () => {
      setLoadingStateWithTimer(true)
      setKpi(defaultKPIs)
      await setLoadingState('LOADING')

      try {
        await onLoadKPI({
          productEventIds: filterProductEventId ? filterProductEventId : undefined, // this sometimes returns ''
          userIds: filterUserId,
          updatedAt: filterUpdatedDate
            ? {
                gtEq: filterUpdatedDate.start,
                ltEq: filterUpdatedDate.end,
              }
            : undefined,
          startedAt: filterCreatedDate
            ? {
                gtEq: filterCreatedDate.start,
                ltEq: filterCreatedDate.end,
              }
            : undefined,
        })
      } finally {
        if (loadingState === 'COMPLETE') {
          setLoading(false)
        }
      }
    }
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // we need to transform the data after querying
  const productEvents = useSelector((state) => get(state, 'assessments.productAssessments', []))
  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  // we need to set this to no cache to get sessions count?
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS)
  const transformAssessmentsAndSave = async (data) => {
    try {
      setTotalAssessmentsCount(get(data, 'getSessionsCount', 0))
      const currentSessions = get(data, 'getSessions', []).map((assessment) => {
        const { id, type, productEventId, user, createdAt, data, updatedAt } = assessment
        const productEvent = productEvents.find(({ id }) => id === productEventId)
        const metadata = get(productEvent, 'metadata', {})
        const survey = get(productEvent, 'metadata.survey.pages', [])
        const productName = get(productEvent, 'metadata.assessmentType', [])
        const shortTitle = get(productEvent, 'metadata.shortTitle', [])
        const populationLabels = get(productEvent, 'metadata.populationLabels', [])
        const numberOfQuestions = survey?.reduce(
          (accumulator, page) => accumulator + page.elements.length,
          0
        )
        const answers = get(data, 'answers', {})
        const status = get(data, 'status', undefined)
        const numberOfAnsweredQuestions = Object.keys(answers).length
        const previousScore = get(assessment, 'data.answers.score.sum')
        const currentScore = getAssessmentScore(answers, metadata)
        if (previousScore !== currentScore?.sum && currentScore?.sum && status === 'Completed') {
          updateAssessment({
            variables: { session: { id: assessment.id, score: currentScore } },
          })
        }

        return {
          user,
          id,
          userId: user.id,
          progress: Math.min(
            Math.round((numberOfAnsweredQuestions * 100) / numberOfQuestions),
            100
          ),
          metadata,
          productName,
          type,
          createdAt,
          data,
          updatedAt,
          status,
          fullName: user.fullName,
          productEventId,
          sortAssessmentName: `${shortTitle}${populationLabels}${user.id}`,
          score: currentScore,
        }
      })
      dispatch(setAssessmentSessions(currentSessions))
      setAssessments(currentSessions)
    } catch (error) {
      if (!error.message.includes('Not Authorized')) {
        console.error(error)
      }
    }
  }

  const [loadAssessments, { loading: loadingTable, refetch: _refetch }] = useLazyQuery(
    GET_ASSESSMENTS_NEW,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const refetch = async () => {
    const { data } = await _refetch()
    transformAssessmentsAndSave(data)
  }

  // we need this function so we can perform load assessments outside of useEffect (onDelete)
  const getLoadAssessmentVariables = () => {
    const filter = {
      types: ['answers'],
    }
    if (filterUserId) filter['userIds'] = [filterUserId]
    if (filterStatus) filter['status'] = [filterStatus]
    if (filterProductEventId) filter['productEventIds'] = [filterProductEventId]
    if (filterCreatedDate?.start) {
      filter['startedAt'] = {
        gtEq: format(filterCreatedDate?.start, 'yyyy-MM-dd'),
        ltEq: format(filterCreatedDate?.end, 'yyyy-MM-dd'),
      }
    }
    if (filterUpdatedDate?.start) {
      filter['updatedAt'] = {
        gtEq: format(filterUpdatedDate?.start, 'yyyy-MM-dd'),
        ltEq: format(filterUpdatedDate?.end, 'yyyy-MM-dd'),
      }
    }
    return {
      filter,
      includeCount: true,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
      sort: [['updatedAt', 'DESC']],
    }
  }
  // this hook will watch the following values and rerender app?
  useOnValueChange(
    JSON.stringify({
      // filters
      filterUserId,
      filterProductEventId,
      filterCreatedDate,
      filterUpdatedDate,
      filterStatus,
      // pagination
      page,
      rowsPerPage,
      // make sure we have product Events
      productEvents,
    }),
    async () => {
      // only load assessments when we have product events
      if (productEvents.length) {
        const variables = getLoadAssessmentVariables()
        const { data } = await loadAssessments({ variables })
        await transformAssessmentsAndSave(data)
      }
    }
  )

  // this lazy function is for creating and updating assessments
  const [_loadAssessmentsForUpsert] = useLazyQuery(GET_ASSESSMENT_FOR_UPSERT, {
    variables: {
      limit: 20,
      userIds: [],
    },
    fetchPolicy: 'no-cache',
  })
  // TODO: make sure we can query sessions by id
  // The difference for this one is that we don't save into state as its only for one page
  const loadAssessmentsForUpsert = async ({ userIds, sessionId }) => {
    const variables = {
      filter: {
        userIds,
      },
      limit: 100,
    }
    const response = await _loadAssessmentsForUpsert({ variables })

    // parse response and find session
    const assessment = get(response, 'data.getSessions', []).find(({ id }) => id === sessionId)

    // parse assesment
    const updatedAt = get(assessment, 'updatedAt', undefined)
    const sessionData = get(assessment, 'data', {})
    const sessionProductEventId = get(assessment, 'productEventId')

    // get productEvent for metadata info
    const productEvent = productEvents.find(({ id }) => sessionProductEventId === id)
    const _metadata = get(productEvent, 'metadata', {})
    const productId = get(productEvent, 'productId', null)
    const { survey, isIntakeForm, assessmentName } = parseMetadata(_metadata)

    // modify the title ( we have our own custom header below..)
    const newSurvey = {
      ...survey,
      title: '',
    }

    // output
    return {
      survey: newSurvey,
      title: assessmentName,
      metadata: _metadata,
      sessionData,
      isIntakeForm,
      updatedAt,
      productId,
      productEvent,
    }
  }

  /**
   * ON EXIT - Clean up
   */
  useEffect(() => {
    return () => {
      dispatch(resetFilter())
    }
  }, [])

  // reset filters when we leave Assessments
  useEffect(() => {
    return () => {
      dispatch(setFilterDates([null, null]))
      dispatch(setAssessmentName())
      dispatch(
        setUser({
          userId: null,
          fullName: null,
          email: null,
          birthYear: null,
          country: null,
          gender: null,
          productPreferences: null,
        })
      )
    }
    // eslint-disable-next-line
  }, [])

  const visibleRows = useMemo(() => stableSort(assessments, getComparator(order, orderBy)), [
    order,
    orderBy,
    assessments,
  ])

  return (
    <LoadingPage loading={loading} text={loadingMessage} hideBackground={false}>
      <MainLayout
        title={title}
        isAssessment
        hideHeader={hideHeader}
        hideFilter={hideFilter}
        hideTabs={hideTabs}
      >
        {process.env.REACT_APP_FF_HIDE_POPUPS !== 'true' && (
          <NewUpdates
            title="Introduction to Assessments"
            src={NEW_UPDATE_VIDEO}
            type={`assessment${authUserId}`}
          />
        )}
        <Outlet
          context={{
            data: visibleRows,
            hideHeader,
            setHideHeader,
            setHideFilter,
            setHideTabs,
            title,
            setTitle,
            refetch,
            loading,
            setLoadingStateWithTimer,
            setLoadingMessage,
            // kpis
            kpi,
            loadingKPI,
            getUsers,
            onLoadKPI,

            // pagination
            loadingTable,
            totalCount: totalAssessmentsCount,
            page,
            setPage,
            rowsPerPage,
            handleChangePage,
            handleChangeRowsPerPage,
            handleRequestSort,

            // update and create assesments
            loadAssessmentsForUpsert,

            // this is for deleting
            loadAssessments,
            getLoadAssessmentVariables,
            transformAssessmentsAndSave,
            setLoadingState,
          }}
        />
      </MainLayout>
    </LoadingPage>
  )
}
