export default {
  pages: [
    {
      name: 'page1',

      elements: [
        {
          name: 'question1',

          type: 'rating',

          title: 'Repeated, disturbing, and unwanted memories of the stressful experience? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question2',

          type: 'rating',

          title: 'Repeated, disturbing dreams of the stressful experience?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question3',

          type: 'rating',

          title:
            'Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question4',

          type: 'rating',

          title: 'Feeling very upset when something reminded you of the stressful experience? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question5',

          type: 'rating',

          title:
            'Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question6',

          type: 'rating',

          title: 'Avoiding memories, thoughts, or feelings related to the stressful experience?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question7',

          type: 'rating',

          title:
            'Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question8',

          type: 'rating',

          title: 'Trouble remembering important parts of the stressful experience?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question9',

          type: 'rating',

          title:
            'Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question10',

          type: 'rating',

          title:
            'Blaming yourself or someone else for the stressful experience or what happened after it? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question11',

          type: 'rating',

          title: 'Having strong negative feelings such as fear, horror, anger, guilt, or shame?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question12',

          type: 'rating',

          title: 'Loss of interest in activities that you used to enjoy? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question13',

          type: 'rating',

          title: ' Feeling distant or cut off from other people? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question14',

          type: 'rating',

          title:
            'Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question15',

          type: 'rating',

          title: 'Irritable behavior, angry outbursts, or acting aggressively?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question16',

          type: 'rating',

          title: 'Taking too many risks or doing things that could cause you harm? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question17',

          type: 'rating',

          title: 'Being “superalert” or watchful or on guard?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question18',

          type: 'rating',

          title: 'Feeling jumpy or easily startled? ',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question19',

          type: 'rating',

          title: ' Having difficulty concentrating?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },

        {
          name: 'question20',

          type: 'rating',

          title: 'Trouble falling or staying asleep?',

          hideNumber: true,

          rateValues: [
            {
              text: 'Not at all',

              value: 0,
            },

            {
              text: 'A little bit',

              value: 1,
            },

            {
              text: 'Moderately',

              value: 2,
            },

            {
              text: 'Quite a bit',

              value: 3,
            },

            {
              text: 'Extremely',

              value: 4,
            },
          ],
        },
      ],

      description: 'In the past week, how much were you bothered by: ',
    },
  ],

  title: 'PTSD Checklist for DSM-5 (PCL-5) - Week',

  showTitle: false,

  completeText: 'Skip',

  pageNextText: 'Skip',

  showProgressBar: 'bottom',

  goNextPageAutomatic: true,

  questionsOnPageMode: 'questionPerPage',
}
