export default {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          html:
            '<p class="sv-survey-html">This scale is intended to record your own assessment of any sleep difficulty you might have experienced. Please check the items on the following steps to indicate your estimate of any difficulty, provided that it occurred at least three times per week during the last month.</p><h3 class="sv-survey-ready">Ready to start?</h3>',
          type: 'html',
        },
      ],
    },
    {
      name: 'question1',
      type: 'rating',
      title: 'Sleep induction (time it takes you to fall asleep after turning-off the lights)',
      hideNumber: false,
      rateValues: [
        {
          text: 'No problem',
          value: 0,
        },
        {
          text: 'Slightly delayed',
          value: 1,
        },
        {
          text: 'Markedly delayed',
          value: 2,
        },
        {
          text: 'Very delayed or did not sleep at all',
          value: 3,
        },
      ],
    },
    {
      name: 'page3',
      elements: [
        {
          name: 'question2',
          type: 'rating',
          title: 'Awakenings during the night',
          rateCount: 4,
          rateValues: [
            {
              text: 'No problem',
              value: 0,
            },
            {
              text: 'Minor problem',
              value: 1,
            },
            {
              text: 'Considerable problem',
              value: 2,
            },
            {
              text: 'Serious problem or did not sleep at all',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page4',
      elements: [
        {
          name: 'question3',
          type: 'rating',
          title: 'Final awakening earlier than desired',
          rateCount: 4,
          rateValues: [
            {
              text: 'Not earlier',
              value: 0,
            },
            {
              text: 'A little earlier',
              value: 1,
            },
            {
              text: 'Markedly earlier',
              value: 2,
            },
            {
              text: 'Much earlier or did not sleep at all',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page5',
      elements: [
        {
          name: 'question4',
          type: 'rating',
          title: 'Total sleep duration',
          rateCount: 4,
          rateValues: [
            {
              text: 'Sufficient',
              value: 0,
            },
            {
              text: 'Slightly insufficient',
              value: 1,
            },
            {
              text: 'Markedly insufficient',
              value: 2,
            },
            {
              text: 'Very insufficient or did not sleep at all',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page6',
      elements: [
        {
          name: 'question5',
          type: 'rating',
          title: 'Overall quality of sleep (no matter how long you slept)',
          rateCount: 4,
          rateValues: [
            {
              text: 'Satisfactory',
              value: 0,
            },
            {
              text: 'Slightly unsatisfactory',
              value: 1,
            },
            {
              text: 'Markedly unsatisfactory',
              value: 2,
            },
            {
              text: 'Very unsatisfactory or did not sleep at all',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page7',
      elements: [
        {
          name: 'question6',
          type: 'rating',
          title: 'Sense of well-being during the day',
          rateCount: 4,
          rateValues: [
            {
              text: 'Normal',
              value: 0,
            },
            {
              text: 'Slightly decreased',
              value: 1,
            },
            {
              text: 'Markedly decreased',
              value: 2,
            },
            {
              text: 'Very decreased',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page8',
      elements: [
        {
          name: 'question7',
          type: 'rating',
          title: 'Functioning (physical and mental) during the day',
          rateCount: 4,
          rateValues: [
            {
              text: 'Normal',
              value: 0,
            },
            {
              text: 'Slightly decreased',
              value: 1,
            },
            {
              text: 'Markedly decreased',
              value: 2,
            },
            {
              text: 'Very decreased',
              value: 3,
            },
          ],
        },
      ],
    },
    {
      name: 'page2',
      elements: [
        {
          name: 'question8',
          type: 'rating',
          title: 'Sleepiness during the day',
          rateCount: 4,
          rateValues: [
            {
              text: 'None',
              value: 0,
            },
            {
              text: 'Mild',
              value: 1,
            },
            {
              text: 'Considerable',
              value: 2,
            },
            {
              text: 'Intense',
              value: 3,
            },
          ],
        },
      ],
    },
  ],
  title: 'Athens Insomnia Scale (AIS)',
  showTitle: false,
  showPageTitle: false,
  pageNextText: 'Skip',
  // goNextPageAutomatic: true,
  firstPageIsStarted: true,
  startSurveyText: 'Yes, Continue',
}
