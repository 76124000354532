/**
 * This is a page for new assessment
 */
import React from 'react'
import { DefaultLightPanelless } from 'survey-core/themes'
import { Container, LinearProgress, Stack, Typography } from '@mui/material'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import { getAssessmentScore } from './utils/get-assessment-score'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { parseMetadata } from './utils/parse-metadata'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from './constants/gql'
import { useSnackbar } from 'notistack'
import { ACTIONS } from './utils/constants'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { resetFilter } from 'store/modules/assessments'
import Header from './components/Header'

import { Serializer, Model } from 'survey-core'
import { Survey, ReactSurveyElement, ReactElementFactory } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import gad7 from './utils/gad7'
import bpqSf from './utils/bpq-sf'
import dcdq from './utils/dcdq'
import pcl5Week from './utils/pcl5-week'
import npss from './utils/npss'
import psc from './utils/psc'
import bpqAns from './utils/bpq-ans'
import nq from './utils/nq'
import phq from './utils/phq'
import ais from './utils/ais'
import ace from './utils/ace'
import bbcss13 from './utils/bbcss13'
import bbcss12 from './utils/bbcss12'

// source: https://surveyjs.io/form-library/examples/progress-bar-with-percentage/reactjs#content-code
Serializer.addProperty('survey', 'progressTitle')

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    return (
      <Stack className="sv-progressbar-percentage" spacing={2} alignItems="center" py={3}>
        <LinearProgress
          sx={{ width: '100%' }}
          variant="determinate"
          value={this.props.model.progressValue}
        />
        <Typography>
          {this.props.model.progressText}
          {/* Page {this.props.model.currentPageNo} of {this.props.model.pageCount} */}
        </Typography>
      </Stack>
    )
  }
}

ReactElementFactory.Instance.registerElement('sv-progressbar-percentage', (props) => {
  return React.createElement(PercentageProgressBar, props)
})

export default function CreateAssessment() {
  const { setLoadingState, onLoadKPI, refetch } = useOutletContext()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // get params variables
  const { userId, productEventId: _productEventId } = useParams()
  const productEventId = parseInt(_productEventId)

  // get metadata
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const productId = get(
    productEvents.find(({ id }) => id === productEventId),
    'productId',
    null
  )
  const metadata = get(
    productEvents.find(({ id }) => id === productEventId),
    'metadata',
    {}
  )
  const { assessmentName } = parseMetadata(metadata)

  // gql
  const [createSession] = useMutation(CREATE_SESSION)

  // if first page, don't show next for intake forms
  // const survey = new Model(surveyJSON)
  const mp = {
    'Generalised Anxiety Disorder (GAD-7)': gad7,
    'Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)': bpqAns,
    'Body Perception Questionnaire Short Form (BPQ-SF)': bpqSf,
    'The Developmental Coordination Disorder Questionnaire (DCDQ)': dcdq,
    'Neuroception of Psychological Safety Scale (NPSS)': npss,
    'PTSD Checklist for DSM-5 (PCL-5) - Week': pcl5Week,
    'Pediatric Symptom Checklist (PSC)': psc,
    'Nijmegen Questionnaire (NQ)': nq,
    'Patient Health Questionnaire (PHQ-9)': phq,
    'Athens Insomnia Scale (AIS)': ais,
    'Adverse Childhood Experience for Adults (ACE)': ace,
    'Brain Body Center Sensory Scales (BBCSS) - Full': bbcss13,
    'Brain Body Center Sensory Scales (BBCSS) - Full (Child)': bbcss12,
  }
  const survey = new Model(get(mp, assessmentName, gad7))
  survey.applyTheme(DefaultLightPanelless)

  // console.log(model, model.data)
  const handleComplete = (sender) => {
    const score = getAssessmentScore(sender.data, metadata)
    const data = {
      answers: { ...sender.data, score },
      status: 'Completed',
      currentPageNo: sender.currentPageNo,
    }
    const session = {
      data: JSON.stringify(data).replace(/'/g, '`'),
      productId,
      productEventId,
      startedAt: new Date().toISOString(),
      type: 'answers',
      userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
      metadata: { userAgent: navigator.userAgent },
    }
    handleCreate(session)
  }

  const handleCreate = async (session) => {
    try {
      await setLoadingState(true)
      await createSession({ variables: { session } })
      await refetch()
      await onLoadKPI()
      enqueueSnackbar(ACTIONS['Completed'].message, {
        variant: ACTIONS['Completed'].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      console.error(error)
    } finally {
      setLoadingState(false)
      dispatch(resetFilter())
      navigate(`/assessments`, { state: { hideGettingStarted: true } })
    }
  }

  const handleSend = async () => {
    const surveyData = survey.getData()
    const data = {
      answers: { ...surveyData },
      status: 'Sent To Client',
      currentPageNo: survey.currentPageNo,
    }
    const session = {
      data: JSON.stringify(data).replace(/'/g, '`'),
      productId,
      productEventId,
      startedAt: new Date().toISOString(),
      type: 'answers',
      userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
      metadata: { userAgent: navigator.userAgent },
    }
    handleCreate(session)
  }

  const handleSave = async () => {
    const surveyData = survey.getData()
    const data = {
      answers: { ...surveyData },
      status: 'In Progress',
      currentPageNo: survey.currentPageNo,
    }
    const session = {
      data: JSON.stringify(data).replace(/'/g, '`'),
      productId,
      productEventId,
      startedAt: new Date().toISOString(),
      type: 'answers',
      userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
      metadata: { userAgent: navigator.userAgent },
    }
    handleCreate(session)
  }

  survey.addLayoutElement({
    id: 'progressbar-percentage',
    component: 'sv-progressbar-percentage',
    container: 'footer',
    data: survey,
  })

  return (
    <Container maxWidth="xl">
      <Stack spacing={2}>
        <Header onSend={handleSend} onSave={handleSave} title={assessmentName} />
        <Survey
          model={survey}
          onComplete={handleComplete}
          widthMode="responsive"
          showProgressBar="off"
          showNavigationButtons="bottom"
          goNextPageAutomatic={true}
        />
      </Stack>
    </Container>
  )
}
